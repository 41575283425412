import React from "react";
import Modal from "@components/layout/Modal";

type ModalProps = {
  onClose: () => void;
  isOpen: boolean;
};

const FirstAccess = ({ onClose, isOpen = false }: ModalProps) => {
  const Footer = () => (
    <div className="sm:space-x-4 space-y-4 sm:space-y-0 items-center w-full justify-end flex flex-wrap sm:flex-nowrap"></div>
  );

  return (
    <Modal
      footer={<Footer />}
      title="Primeiro Acesso"
      onClose={() => onClose()}
      isOpen={isOpen}
    >
      <div className='p-8'>
        <p className='text-base text-bold'>
          As informações para o cadastro da senha serão enviadas via email.
        </p>
      </div>
    </Modal>
  );
};

export default FirstAccess;
