import React from "react";
import Modal from "@components/layout/Modal";

type ModalProps = {
  onClose: () => void;
  isOpen: boolean;
};

const RecoverPassword = ({ onClose, isOpen = false }: ModalProps) => {
  const Footer = () => (
    <button
      onClick={onClose}
      type="button"
      className="inline-flex focus:outline-none justify-center rounded-full border border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange sm:col-start-2 sm:text-sm"
    >
      Fechar
    </button>
  );

  return (
    <Modal
      footer={<Footer />}
      title="Recuperação de senha"
      onClose={onClose}
      isOpen={isOpen}
    >
      <div className="p-12">
        <p className="text-base text-gray-500">
          Se o e-mail que você digitou estiver cadastrado no programa TEM Saúde,
          você receberá uma mensagem de recuperação com a URL necessária para a
          criação da nova senha.
        </p>
      </div>
    </Modal>
  );
};

export default RecoverPassword;
