import React, { useState } from 'react';
import PersonalData from './PersonalData';
import Subscription from './Subscription';
import Address from './Address';
import cntl from 'cntl';

type SlideDot = {
  active: boolean
}

const slideDot = ({ active }: SlideDot) => cntl`
  bg-primary
  bg-opacity-40
  block 
  rounded-full 
  w-2 
  h-2

  ${
    active ? cntl`bg-primary w-3 h-3` : cntl``
  }
`;

const UserData = () => {
  const [slide, setSlider] = useState(1);

  const prevSlider = () => {
    if (slide <= 1) {
      return;
    }

    setSlider(slide - 1)
  }

  const nextSlider = () => {
    if (slide >= 3) {
      return;
    }

    setSlider(slide + 1)
  }

  return (
    <div className="w-full px-4 sm:px-0 mx-auto max-w-720">
      {slide === 1 && (<PersonalData />)}
      {slide === 2 && (<Subscription />)}
      {slide === 3 && (<Address />)}

      <div className="flex items-center justify-between my-12">
        <button onClick={prevSlider} className="bg-white focus:outline-none flex justify-center items-center w-12 h-12 shadow border rounded-full">
          <svg className="w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
          </svg>
        </button>

        <div className="flex items-center space-x-2">
          <span className={slideDot({ active: slide === 1 })}></span>
          <span className={slideDot({ active: slide === 2 })}></span>
          <span className={slideDot({ active: slide === 3 })}></span>
        </div>

        <button onClick={nextSlider} className="bg-white focus:outline-none focus:outline-none focus:outline-none flex justify-center items-center w-12 h-12 shadow border rounded-full">
          <svg className="w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
          </svg>
        </button>
      </div>
    </div>
  )
};

export default UserData;
