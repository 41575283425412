import React from "react";
import { Form as FormContainer, Input } from "@components/form";
import * as yup from "yup";

const schema = yup.object().shape({
  password: yup.string().required("Preencha sua senha"),
  password_confirmation: yup.string().required("Confirme sua senha").oneOf([yup.ref('password'), null], 'As senhas não são iguais')
});

export type FormData = {
  password: string
  password_confirmation: string
}

type FormProps = {
  submitHandler: (data: FormData) => void
}

const Form = ({ submitHandler }: FormProps): JSX.Element => {
  return (
    <>
      <header className="border-b py-3 text-xl mb-5">
        <h3 className="font-semibold">Recuperação de senha</h3>
      </header>
      <FormContainer schema={schema} onSubmit={submitHandler}>
        <Input
          containerClass="pb-5"
          name="password"
          label="Nova senha"
          type="password"
          placeholder="Digite"
          required
        />
        <Input
          name="password_confirmation"
          label="Confirme sua nova senha"
          type="password"
          placeholder="Digite"
          required
        />

        <button className="mt-16 focus:outline-none bg-primary w-full text-white font-bold rounded-full px-4 py-2">
          Confirmar e Alterar
        </button>
      </FormContainer>
    </>
  );
};

export default Form;
