import React from "react";

const Content = (): JSX.Element => {
  return (
    <div className="h-full w-full flex flex-wrap items-center">
      <div className="p-6">
        <h3 className="text-4xl font-bold">
          Dito e feito: assinatura cancelada.
        </h3>
        <p className="my-5 text-black-70 text-xl">
          Mas estamos sempre de braços abertos esperando você de volta: é só fazer de novo a contratação em nosso site.
        </p>
      </div>

      <a href="http://meutem.com.br/visaempresas" className="mt-12 focus:outline-none bg-primary w-full text-center text-black font-bold rounded-full px-4 py-2">
        Painel Administrativo
      </a>
    </div>
  );
};

export default Content;
