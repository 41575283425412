import { createContext } from "react";

export type AuthParams = {
  email: string;
  password: string;
};

export type Address = {
  zipcode: string;
  street: string;
  number: string;
  complement: string;
  neighborhood: string;
  city: string;
  state: string;
};

// export type Term = {
//   content: string
//   term_id: string
//   customer_id: string
//   ip: string
// }

export type PasswordResetForm = {
  password: string;
  password_confirmation: string;
  reset_password_token: string;
};

export type Collaborator = {
  name: string;
  document_number: string;
  cpf: string;
  birthdate: Date;
  phone_number: string;
  email: string;
  zip_code: string;
  address: string;
  number: string;
  complement: string;
  neighborhood: string;
  city: string;
  state: string;
};

export type UpdateCompany = {
  slots?: number;
  password: string;
};

export type Company = {
  name: string;
  password?: string;
  current_password?: string;
  password_confirmation?: string;
  owner_attributes: Partial<ManagerAttributes>;
};

export type ManagerAttributes = {
  id: string;
  full_name: string;
  document_number: string;
  birthdate: string;
  phone_number: string;
  zip_code: string;
  address: string;
  number: string;
  complement: string;
  neighborhood: string;
  city: string;
  state: string;
  card_number: number;
  expiration_month: number;
  expiration_year: number;
  security_code: number;
};

export type ApiInstance = {
  auth(data: AuthParams): Promise<any>;
  // showTerm(): Promise<any>,
  // acceptTerm(term_id: string, ip: string): Promise<any>,
  listCollaborators(): Promise<any>;
  getCollaborator(id: string): Promise<any>;
  createCollaborator(form: Collaborator): Promise<any>;
  getCompany(): Promise<any>;
  updateCompany(form: UpdateCompany): Promise<any>;
  cancelSubscription(): Promise<any>;
  removeCollaborator(id: string): Promise<any>;
  recoverPassword(email: string, basePath?: string): Promise<any>;
  resetPassword(form: PasswordResetForm): Promise<any>;
  listAddresses(): Promise<any>;
  getAddress(id: string): Promise<any>;
  registerAddress(form: Company): Promise<any>;
  updateAddress(address: { address: Address }, id: string): Promise<any>;
  removeAddress(id: string): Promise<any>;
  getPartner(partner: string): Promise<any>;
};

export default createContext<ApiInstance>({
  async auth(data: AuthParams) {},
  // async showTerm() {},
  // async acceptTerm(term_id: string, ip: string) {},
  async listCollaborators() {},
  async getCollaborator(id: string) {},
  async createCollaborator(form: Collaborator) {},
  async getCompany() {},
  async updateCompany(form: UpdateCompany) {},
  async cancelSubscription() {},
  async removeCollaborator(id: string) {},
  async recoverPassword(email: string, basePath?: string) {},
  async resetPassword(form: PasswordResetForm) {},
  async listAddresses() {},
  async getAddress(id: string) {},
  async registerAddress(form: Company) {},
  async updateAddress(address: { address: Address }, id: string) {},
  async removeAddress(id: string) {},
  async getPartner(partner: string) {},
});
