import { useCompany } from "@hooks";
import React from "react";
import { maskedText } from "@helpers/format";

const PersonalData = () => {
  const { company, showPersonalDataForm } = useCompany();

  return (
    <section className="w-full mx-auto max-w-720">
      <h4 className="text-2xl text-center sm:text-left text-black-100 font-bold mt-12 mb-10">
        Dados de cadastro
      </h4>

      <div className="shadow-md w-ful mx-auto max-w-720 rounded-3xl bg-white p-6">
        <div className="w-full sm:px-5">
          <div className="flex mb-2">
            <div className="w-1/2 text-base text-black-100 font-bold">
              Nome da empresa
            </div>
            <div className="w-1/2 text-right text-black-70">
              {company?.name}
            </div>
          </div>
          {/* <div className="flex mb-2">
            <div className="w-1/2 text-base text-black-100 font-bold">
              CPF
            </div>
            <div className="w-1/2 text-right text-black-70">
              {maskedText(company?.owner.document_number ?? '', '###.###.###-##')}
            </div>
          </div> */}
          <div className="flex mb-2">
            <div className="w-1/2 text-base text-black-100 font-bold">CNPJ</div>
            <div className="w-1/2 text-right text-black-70">
              {maskedText(company?.document_number ?? "", "##.###.###/####-##")}
            </div>
          </div>
          <div className="flex mb-2">
            <div className="w-1/2 text-base text-black-100 font-bold">
              Telefone
            </div>
            <div className="w-1/2 text-right text-black-70">
              {maskedText(
                company?.owner.phone_number ?? "",
                "### (##) #####-####"
              )}
            </div>
          </div>
          {/* <div className="flex mb-2">
            <div className="w-1/2 text-base text-black-100 font-bold">
              Celular
            </div>
            <div className="w-1/2 text-right text-black-70">
              {maskedText(
                company?.owner.phone_number ?? "",
                "### (##) #####-####"
              )}
            </div>
          </div> */}
          <div className="flex mb-2">
            <div className="w-1/2 text-base text-black-100 font-bold">
              E-mail
            </div>
            <div className="w-1/2 text-right text-black-70">
              {company?.owner?.email}
            </div>
          </div>
        </div>

        <button
          onClick={() => showPersonalDataForm(true)}
          className="bg-primary bg-opacity-5 mt-5 w-full py-3 rounded-full text-primary-70"
        >
          Alterar
        </button>
      </div>
    </section>
  );
};

export default PersonalData;
