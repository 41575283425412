import React, {  useState } from 'react';
import Content from './components/Content';
import Signin from './components/Signin';
import { Loader } from "@components/layout";
import RecoverPassword from '@components/modals/RecoverPassword';
import { useCompany } from "@hooks";

export const Index = (): JSX.Element => {
  const [isOpened, setOpened] = useState(false);
  const [cookies, setCookies] = useState(false);
  const { basePath, isLoadingCompany, isLoadingTheme } = useCompany();

  const acceptCookies = () => setCookies(true);

  if (isLoadingCompany && isLoadingTheme) {
    return <Loader />
  }

  return (
    <>
      <main className="grid grid-cols-1 sm:grid-cols-4 gap-0 h-full">
        <section className="bg-baby col-span-3 hidden sm:block">
          {!cookies && <div className="absolute bg-gray-300 w-full flex flex-col items-center p-4">
            <p className="text-base font-bold">
            Esse site salva seu histórico de uso. Ao continuar navegando você concorda com a política de privacidade.
            </p>
            <button 
              onClick={acceptCookies}
              className="bg-black-100 focus:outline-none text-white text-base font-bold px-4 py-2 ml-4 rounded">
              Aceitar Cookies
            </button>
          </div>}
          <Content companySlug={basePath ?? null} />
        </section>
        <section className="shadow">
          <Signin onRecoverPassword={() => setOpened(true)} />
        </section>
      </main>
      <RecoverPassword onClose={() => setOpened(false)} isOpen={isOpened} />
    </>
  )
}
