import React, { useState } from "react";
import Modal from "@components/layout/Modal";
import { useApi, useToast } from "@hooks";
import { useCompany } from "@hooks";
import * as S from "./styles";

type ModalProps = {
  onClose: () => void;
  isOpen: boolean;
};

const AddRemoveSlots = (
  { onClose, isOpen = false }: ModalProps,
) => {
  const api = useApi();
  const { company, loadCompany } = useCompany();
  const toast = useToast();
  const [count, setCount] = useState<number>(0);
  const [add, setAdd] = useState<boolean>(true);
  const [remove, setRemove] = useState<boolean>(!add);
  const [updated, setUpdated] = useState(false);


  const toogle = () => {
    setAdd(!add);
    setRemove(!remove);
  };
  const decrement = () => {
    if (count === 0) return
    setCount(count - 1);
  };

  const increment = () => {
    if (count === 30) return;
    setCount(count + 1);
  };

  const removeSlots = async () => {
    try {
      const nSlots: number = company?.slots ? company?.slots : 0
      const nContributors: number = company?.contributors?.length ? company?.contributors.length : 0
      if (add) {
        if (count + nSlots <= 30) {
          const form = {slots: count + nSlots, password: ''}
          await api.updateCompany(form);
        } else {
          if (count + nContributors + nSlots > 30) toast.error(`Falha ao adicionar vidas 😐`, 'Você atingiu o número limite de colaboradores.')
        }
      }
      if (remove) {
        if (count <= nSlots - nContributors) {
          const form = {slots: nSlots - count, password: ''};
          await api.updateCompany(form);
        } else {
          if (count > nSlots - nContributors) toast.error('Falha ao remover vidas 😐','Você não tem vidas livres para remover, favor remover algum colaborador.')
        }
      }
    } catch (error) {
      if(error?.response?.status === 401){
        toast.error(
          "Falha ao adicionar/remover Vidas 😐",
          "Por favor, tente novamente"
        );
      }
    } finally {
      setUpdated(true)
      loadCompany();
    }
  };

  const Footer = () => (
    <div className="sm:space-x-4 space-y-4 sm:space-y-0 items-center w-full justify-end flex flex-wrap sm:flex-nowrap">
      <button
        onClick={() =>  {
          if (updated) {
            onClose();
            setUpdated(false);
            setCount(0);
          } else {
            removeSlots();
          }
        }}
        disabled={count <= 0}
        className={
          count > 0
            ? "bg-primary focus:outline-none mt-5 w-full py-3 rounded-full text-black-100"
            : "bg-gray-200 focus:outline-none mt-5 w-full py-3 rounded-full text-white"
        }
      >
        {updated ? 'OK' : 'Concordar e Confirmar'}
      </button>
    </div>
  );

  return (
    <Modal
      footer={<Footer />}
      title="Adicionar ou remover vidas"
      onClose={() => {
        onClose();
        setUpdated(false);
        setCount(0);
      }}
      isOpen={isOpen}
    >
      {updated ? (
        <div className='m-10'>
          <p className='font-bold'>{add ? 'Inclusão' : 'Remoção'} de vidas realizada com sucesso.</p>
        </div>
      ) : (
        <div className="p-12 flex flex-col items-center">
          <p className="text-base text-gray-500 text-center">
            Qualquer inclusão ou remoção de vidas,
            <br />o valor final do programa de saúde sofrerá alterações na
            próxima fatura.
          </p>
          <div>
            <S.WrapperSwitch>
              <S.ButtonSwitch
                className="focus:outline-none"
                onClick={() => toogle()}
                disabled={add}
                isActive={add}
              >
                Adicionar
              </S.ButtonSwitch>
              <S.ButtonSwitch
                className="focus:outline-none"
                onClick={() => toogle()}
                disabled={remove}
                isActive={remove}
              >
                Remover
              </S.ButtonSwitch>
            </S.WrapperSwitch>
            <S.Wrapper>
              <S.Button
                onClick={() => decrement()}
                className="focus:outline-none border-primary text-primary"
              >
                -
              </S.Button>
              <S.Count>{count}</S.Count>
              <S.Button
                onClick={() => increment()}
                className="focus:outline-none border-primary text-primary"
              >
                +
              </S.Button>
            </S.Wrapper>
          </div>
          {add ? (
            <p className="text-base text-gray-500 text-center">
              Importante: máximo de vidas 30.
            </p>
          ) : (
            <p className="text-base text-gray-500 text-center">
              Importante: é necessário ter a quantidade de vidas livres para
              remover, caso não tenha, descadastre algum colaborador.
            </p>
          )}
        </div>
      )}
    </Modal>
  );
};

export default AddRemoveSlots;
