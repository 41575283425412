import { createContext } from "react";
import { Contributor } from "./Contributor";

export interface Root {
  id: string;
  cnpj: string;
  email: string;
  name: string;
  slots: number;
  document_number: string;
  status: string;
  contributors: Contributor[];
  address: Address[];
  owner: Manager;
}

export interface Plan {
  amount: number;
  external_reference: string;
  name: string;
  onix: number;
  payment_methods: PaymentMethod[];
  recurrence: number;
}

export interface PaymentMethod {
  label: string;
  value: string;
}

export interface Address {
  id: string;
  zipcode: string;
  street: string;
  number: string;
  complement: string;
  neighborhood: string;
  city: string;
  state: string;
  created_at: string;
  updated_at: string;
}

export interface Manager {
  id: string;
  name: string;
  document_type: string;
  document_number: string;
  phone_number: string;
  email: string;
  zip_code: string;
  street: string;
  number: string;
  complement: string;
  neighborhood: string;
  city: string;
  state: string;
  birthdate: string;
  created_at: string;
  updated_at: string;
}

export interface SubscriptionCard {
  pan: string;
  expiryMonth: number;
  expiryYear: number;
  effectiveBrand: string;
}

interface CompanyContext {
  basePath: string;
  company: Root | null;
  logo: string;
  setCompany(company: Root): void;
  loadCompany(): Promise<void>;
  showPersonalDataForm(show: boolean): void;
  showPaymentForm(show: boolean): void;
  showAddressForm(show: boolean): void;
  showCancelSubscriptionModal(show: boolean): void;
  isLoadingCompany: boolean;
  isLoadingTheme: boolean;
  showningPersonalDataForm: boolean;
  showningPaymentForm: boolean;
  showningAddressForm: boolean;
  showningCancelSubscriptionModal: boolean;
}

export default createContext<CompanyContext>({
  basePath: "",
  setCompany(company: Root) {},
  async loadCompany() {},
  company: null,
  logo: "",
  showPersonalDataForm(show: boolean) {},
  showPaymentForm(show: boolean) {},
  showAddressForm(show: boolean) {},
  showCancelSubscriptionModal(show: boolean) {},
  isLoadingCompany: false,
  isLoadingTheme: false,
  showningPersonalDataForm: false,
  showningPaymentForm: false,
  showningAddressForm: false,
  showningCancelSubscriptionModal: false,
});
