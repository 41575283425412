import React, {useState } from "react";
import ContributorContext, { Contributor } from "../@contexts/Contributor";

interface ProviderProps {
  children: JSX.Element;
}

export const ContributorProvider = ({ children }: ProviderProps): JSX.Element => {
  const [currentContributor, setContributor] = useState<Partial<Contributor>|null>(null);

  return (
    <ContributorContext.Provider
      value={{ 
        currentContributor, 
        setContributor,
      }}
    >
      {children}
    </ContributorContext.Provider>
  );
};

export default ContributorProvider;
