import { createContext } from "react";

type ToastContext = {
  error(title: string, message: string): void
  success(title: string, message: string): void
}

export default createContext<ToastContext>({
  error(title: string, message: string) {},
  success(title: string, message: string) {}
});;
