import React from "react";
import { Route, Redirect } from "react-router-dom";
import { hasTokens } from "@utils/auth";

const PrivateRoute: React.FC<{
  component: React.FC;
  path: string;
  exact: boolean;
}> = (props) => {
  return hasTokens() ? (
    <Route path={props.path} exact={props.exact} component={props.component} />
  ) : (
    <Redirect to="/acesso" />
  );
};
export default PrivateRoute;
