import React from "react";
import get from "lodash.get";
import { useFormContext } from "react-hook-form";

type NativeProps = React.InputHTMLAttributes<HTMLSelectElement>;

type OptionsProps = {
	label: string
	value: string
}

type InputProps = {
  name: string;
  label?: string;
  containerClass?: string;
  options: OptionsProps[];
} & NativeProps;


function Select(props: InputProps): JSX.Element {
  const { register, errors } = useFormContext();  

  const {
    label,
    options,
    id,
    name,
    required,
    containerClass,
    placeholder,
  } = props;
  const hasError = get(errors, props.name);
  const inputCommonProps = {
    ...props,
    className: `${props.className ?? ""} w-full bg-gray-100 border p-4 ${
      props.type !== "checkbox" ? "h-14" : ""
    } rounded-md ${
      props.disabled && "opacity-50"
    } focus:border-gray-200 focus:bg-white focus:outline-none ${
      typeof hasError !== "undefined" && "border-red"
    }`,
  };

  delete inputCommonProps.containerClass;

  return (
    <section className={`${containerClass ?? ""}`}>
      {label && (
        <label htmlFor={id || name} className="block text-base mb-3">
          {label} {required && <span className="text-red">*</span>}
        </label>
      )}
      <select {...inputCommonProps} value={props.defaultValue} ref={register}>
      <option value="">{placeholder}</option>
				{options.map((option: OptionsProps) => (
					<option
						key={`option_${option.value}`}
						value={option.value}
					>
						{option.label}
					</option>
				))}
      </select>
    </section>
  );
};

export default Select;
