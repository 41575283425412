import React from "react";

const Content = (): JSX.Element => {
  return (
    <div className="h-full w-full flex flex-wrap items-center">
      <div className="w-full flex justify-center items-center border rounded-3xl border-orange-40 h-64 bg-baby">
        <div className="bg-white shadow-orange flex justify-center items-center text-primary h-36 w-36 border border-orange-25 rounded-full">
          <svg
            className="h-28"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5 13l4 4L19 7"
            />
          </svg>
        </div>
      </div>
      <h3 className="mt-12 text-3xl font-bold">Senha alterada!</h3>
      <p className="my-5 text-black-70 text-xl">
        Agora é só clicar no botão abaixo para logar no seu painel
        administrativo ;)
      </p>
      <a href="/acesso" className="mt-12 text-center bg-primary w-full text-white font-bold rounded-full px-4 py-2">
        Painel Administrativo
      </a>
    </div>
  );
};

export default Content;
