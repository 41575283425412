import { toDisplayDate } from "@helpers/format";
import { useCompany } from "@hooks";
import React from "react";


const PersonalData = () => {
  const {
    company,
    showCancelSubscriptionModal,
  } = useCompany();

  return (
    <section className="w-full mx-auto max-w-720">
      <h4 className="text-2xl text-center sm:text-left text-black-100 font-bold mt-12 mb-10">
        Dados da assinatura
      </h4>

      <div className="shadow-md w-ful mx-auto max-w-720 rounded-3xl bg-white p-6">
        <div className="w-full sm:px-5">
          <div className="flex mb-2">
            <div className="w-1/2 text-base text-black-100 font-bold">
              Data de início
            </div>
            <div className="w-1/2 text-right text-black-70">
              {toDisplayDate(company?.owner.created_at)}
            </div>
          </div>
          <div className="flex mb-2">
            <div className="w-1/2 text-base text-black-100 font-bold">
              Colaboradores Cadastrados
            </div>
            <div className="w-1/2 text-right text-black-70">
              {company?.contributors?.length}
            </div>
          </div>
        </div>
        <button
          onClick={() => showCancelSubscriptionModal(true)}
          className="bg-primary bg-opacity-5 mt-5 w-full py-3 rounded-full text-primary-70"
        >
          Cancelar Assinatura
        </button>
      </div>
    </section>
  );
};

export default PersonalData;
