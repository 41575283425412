import React, { useEffect, useState, useCallback } from "react";
import Header from "@components/dashboard/Header";
import Footer from "@components/layout/Footer";
import UserData from "./components/UserData";

import { useCompany, useContributor } from "@hooks";

import NewUser from "@components/modals/NewUser";
import EditUserModal from "@components/modals/EditManager";
import AddRemoveSlots from "@components/modals/AddRemoveSlots";
import RemoveCollaboratorModal from "@components/modals/RemoveCollaborator";
import CancelSubscriptionModal from "@components/modals/CancelSubscription";
import { Contributor } from "@contexts/Contributor";
import Loading from "@components/layout/Loading";
import Loader from "@components/layout/Loader";
// import PrivacyTerm from "@components/modals/PrivacyTerm";

type UnknownContributor = {
  id?: string;
  name?: string;
  email?: string;
};

type ContributorItem = UnknownContributor | Contributor;

export const Index = (): JSX.Element => {
  const [appReady, setAppReady] = useState<boolean>(false);
  const [isSlotModalVisible, setSlotModal] = useState<boolean>(false);
  const [isRemoveModalVisible, setRemoverModalVisible] = useState<boolean>(
    false
  );
  const [isNewModalVisible, setNewModalVisible] = useState<boolean>(false);
  // const [isTermModalVisible, setTermModalVisible] = useState<boolean>(false);
  const {
    company,
    loadCompany,
    showningPersonalDataForm,
    showningAddressForm,
    showningPaymentForm,
    showningCancelSubscriptionModal,
    showAddressForm,
    showPersonalDataForm,
    showPaymentForm,
    showCancelSubscriptionModal,
    isLoadingCompany,
  } = useCompany();

  const { setContributor } = useContributor();

  const getCompanyData = useCallback(async () => {
    try {
      await loadCompany()
      setAppReady(true);
    } catch (error) {
      // setTermModalVisible(true)
    }
  }, [loadCompany]);

  useEffect(() => {
    getCompanyData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 

  const slotsLeftOver = (company?.slots ? company?.slots : 0) - (company?.contributors?.length ? company?.contributors.length : 0)

  const contributors: ContributorItem[] = [
    ...(company?.contributors || []),
    ...Array.from({ length: slotsLeftOver || 0 }, (_, i) => ({
      id: String(i + 1),
      name: "Não cadastrado",
      new: true,
    })),
  ];

  const removeCollaborator = (item: ContributorItem) => {
    setContributor(item);
    setRemoverModalVisible(true);
  };

  const closeEditModal = () => {
    showningPersonalDataForm && showPersonalDataForm(false);
    showningAddressForm && showAddressForm(false);
    showningPaymentForm && showPaymentForm(false);
  };

  if (isLoadingCompany && !appReady) {
    return <Loader />;
  }

  return (
    <>
      <Header />
      <section className="h-300 bg-primary mt-20 sm:mt-0">
        <div className="max-w-720 mx-auto text-base sm:text-xl text-black-100 font-bold pl-4 py-12">
          <p>
            Oi, tudo bem? <br />
            Sou seu painel administrativo da TEM Saúde ;)
            <br />
            Sempre bom te ver por aqui!
          </p>
        </div>
      </section>

      <section className="w-full mx-auto max-w-720 mb-12">
        <div className="shadow-md -mt-30 mx-4 sm:mx-0 rounded-3xl bg-white p-6">
          <h3 className="text-black-100 text-2xl mb-4 font-bold">
            Cadastro de colaboradores
          </h3>
          <p className="text-black-70 text-base">
            Cadastrar seus colaboradores nunca foi tão rápido e fácil. Para cada
            voucher, basta clicar em cadastrar, prencher os dados e tcharam!
            Cada colaborador cadastrado receberá um e-mail de boas-vindas com os
            dados do cartão TEM para usar e abusar na rede credenciada.
            <br />
            <br />
            Ah, e não esquece se cadastrar também, viu? 😜
          </p>

          <section className="w-full flex justify-end ml-auto mr-0">
            <button 
              onClick={() => setSlotModal(true)} 
              disabled={company?.status === 'inactive'}
              className="bg-primary focus:outline-none mt-5 py-3 px-6 rounded-full text-black-100">
              Adicionar ou Remover
            </button> 
          </section>
          
          <section className="sm:border overflow-hidden pb-3 w-full sm:mx-auto sm:max-w-720 sm:rounded-xl bg-white mt-12">
            <div className="w-full">
              <div className="hidden sm:flex shadow rounded-t-lg">
                <div className="w-2/4 p-6">#</div>
                <div className="w-2/4 p-6">Colaborador</div>
                <div className="w-full"></div>
              </div>
              <div className="h-300 overflow-y-auto space-y-4 sm:space-y-0">
                {isLoadingCompany ? (
                  <div className="h-full flex items-center justify-center">
                    <Loading />
                  </div>
                ) : (
                  contributors.length > 0 &&
                  contributors.map((item, i) => (
                    <div
                      key={i}
                      className="flex border p-6 rounded-xl sm:p-0 sm:border-0 sm:flex-nowrap flex-wrap"
                    >
                      <div className="w-2/4 sm:border-b pr-3 sm:p-6">
                        {i + 1}
                      </div>
                      <div className="w-full text-black-70 mt-4 sm:mt-0 sm:w-2/4 whitespace-nowrap sm:border-b sm:p-6">
                        {item?.name}
                      </div>
                      <div className="w-full pt-4 mt-6 border-t text-center sm:mt-0 sm:text-right sm:border-t-0 sm:border-b sm:p-6">
                        {item?.email ? (
                          <button
                            onClick={() => removeCollaborator(item)}
                            className="text-primary focus:outline-none hover:text-primary font-bold"
                          >
                            Remover
                          </button>
                        ) : (
                          <button
                            onClick={() => setNewModalVisible(true)}
                            className="text-primary focus:outline-none hover:text-primary font-bold"
                          >
                            Cadastrar
                          </button>
                        )}
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </section>
        </div>
      </section>
      <section className="w-full mx-auto max-w-720">
        <UserData />
      </section>
      <NewUser
        onClose={() => setNewModalVisible(false)}
        isOpen={isNewModalVisible}
      />
      <AddRemoveSlots 
        onClose={() => setSlotModal(false)}
        isOpen={isSlotModalVisible}
      />
      <RemoveCollaboratorModal
        onClose={() => setRemoverModalVisible(false)}
        isOpen={isRemoveModalVisible}
      />
      <CancelSubscriptionModal
        onClose={() => showCancelSubscriptionModal(false)}
        isOpen={showningCancelSubscriptionModal}
      />
      <EditUserModal
        onClose={closeEditModal}
        isOpen={
          showningPersonalDataForm || showningAddressForm || showningPaymentForm
        }
      />
      {/* <PrivacyTerm
        onClose={() => setTermModalVisible(false)}
        isOpen={isTermModalVisible}
      /> */}
      <Footer />
    </>
  );
};
