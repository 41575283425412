import { useTranslation } from 'react-i18next'

export const FormatAmount = (value: number) => (value / 100).toLocaleString('pt-BR', {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2
})

export const FormatRecurrence = (value: number) => {
  const { t } = useTranslation()
  const periods = [t('pricing.recurrence.month'), t('pricing.recurrence.year')]
  const recurrence = [30, 360]
  return periods[recurrence.indexOf(value)]
}

export const toStoreDate = (date: string) => {
  const [d, m, y] = date.split('/');

  return `${y}-${m}-${d}`;
}

export const toDisplayDate = (date?: any) => {
  if (!date) {
    return '';
  }

  const currentDate = date.includes('T') ? date.split('T')[0] : date;
  const [y, m, d] = currentDate.split('-');

  return `${d}/${m}/${y}`;
}

export const maskedText = (value: string, mask: string): string => {
  let i = 0;
  return mask.replace(/#/g, () => value[i++] || '');
};
