import styled from "styled-components";

interface active {
  isActive: boolean;
}
export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 30px 52px;
`
export const Button = styled.button`
  height: 20px;
  width: 20px;
  margin: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border-width: 2px;
`
export const Count =styled.div`
  height: 56px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-color: rgba(19, 5, 1, 0.12);
  border-radius: 6px;
`

export const WrapperSwitch = styled.div.attrs({
  className: 'border-primary'
})`
  height: 60px;
  width: 318px;
  margin-top: 30px;
  border-width: 3px;
  border-radius: 30px;
  display: flex;
  align-self: center;
  flex-direction: row;
  justify-content: center;
`;

export const ButtonSwitch = styled.button<active>`
  margin: ${props => props.isActive ? -2: 2}px;
  background-color: ${props => props.isActive ? 'rgb(var(--primary))' : 'white'};
  color: 'black';
  border-radius: 30px;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
`;
