import React from "react";
import { Route, Switch } from "react-router-dom";

import PrivateRoute from '@components/PrivateRoute';

import { Index as Signing } from "./@containers/Signin";
import { Index as ResetPassword } from "./@containers/ResetPassword";
import { Index as Dashboard } from "./@containers/Dashboard";
import { Index as SubscriptionCancelled } from "./@containers/SubscriptionCancelled";

interface RoutesProps {
  base: string;
}

const Routes = ({ base }: RoutesProps): JSX.Element => {
  return (
    <Switch>
      <Route path="/assinatura-cancelada" component={SubscriptionCancelled} />
      <Route path="/resetar-senha" component={ResetPassword} />
      <Route exact path="/acesso" component={Signing} />
      <PrivateRoute exact path="/" component={Dashboard} />
    </Switch>
  );
};

export default Routes;
