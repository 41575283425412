import React from 'react'

interface NotificationProps {
	title: string
	message?: string
	variant: 'success' | 'error'
	open: boolean
	onClose?: Function
}

const Notification = ({
	title,
	message,
	variant,
	open,
	onClose
}: NotificationProps): JSX.Element => {
	return (
		open ? (
			<section className="fixed z-30 inset-0 flex items-end justify-center px-4 py-6 pointer-events-none sm:p-6 sm:items-start sm:justify-end">
				<section className="max-w-sm w-full bg-white shadow-orange rounded-md pointer-events-auto overflow-hidden">
					<section className="p-4">
						<section className="flex items-center">
							<section className="flex-shrink-0">
								{variant === 'success' && (
									<svg className="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
										<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
									</svg>
								)}
								{variant === 'error' && (
									<svg className="h-6 w-6 text-red" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
										<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
									</svg>
								)}
							</section>
							<section className="ml-3 w-0 flex-1 pt-0.5">
								<p className="text-sm font-medium text-base">
									{title}
								</p>
								{message && (
									<p className="mt-1 text-sm text-gray-700">
										{message}
									</p>
								)}
							</section>
							<section className="ml-4 flex-shrink-0 flex">
								<button
									type="button"
									className="bg-white focus:outline-none rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none"
									onClick={() => onClose?.(false)}
								>
									<span className="sr-only">Close</span>
									<svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
										<path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
									</svg>
								</button>
							</section>
						</section>
					</section>
				</section>
			</section>
		) : <></>
	)
}

export default Notification
