import React from "react";

const Content = ({companySlug}: {companySlug: string | null}): JSX.Element => {
  return (
    <div className="h-full w-full flex items-center justify-center">
      <img alt="Surface notebook" src={!companySlug || companySlug !== 'afinz' ? '/images/surface.svg' : '/images/note_afinz.png'} />
    </div>
  );
};

export default Content;
