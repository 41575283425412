import React from 'react';
import { useCompany } from "@hooks";
import styled from 'styled-components';

const Logo = styled.img`
	width: 100px;
`;

const Header = (): JSX.Element => {
  const { logo } = useCompany();

	return (
		<header className="bg-white w-full h-20 shadow-sm mb-12">
			<section className="flex items-center justify-center h-full">
				<Logo src={logo} alt="logo" />
			</section>
		</header>
	)
};

export default Header
