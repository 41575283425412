import axios, { AxiosInstance, AxiosResponse } from "axios";
import InvalidCredentials from "@errors/InvalidCredentials";
import { AUTH_TOKEN, AUTH_USER, AUTH_CLIENT } from "@utils/constants";
import { hasTokens, removeTokens } from "@utils/auth";
import {
  AuthParams,
  Collaborator,
  Company,
  PasswordResetForm,
  UpdateCompany,
} from "@contexts/Api";

type ApiProps = {
  baseUrl?: string;
};

type Address = {
  zipcode: string;
  street: string;
  number: string;
  complement: string;
  neighborhood: string;
  city: string;
  state: string;
  created_at: string;
  updated_at: string;
};

const getHeaders = () => {
  const user = JSON.parse(localStorage.getItem(AUTH_USER) as string);
  return {
    "access-token": localStorage.getItem(AUTH_TOKEN),
    uid: user.email,
    client: localStorage.getItem(AUTH_CLIENT),
  };
};
export default class ApiService {
  protected readonly instance: AxiosInstance;
  protected apiKey: string | undefined;

  public constructor({ baseUrl }: ApiProps) {
    const token = localStorage.getItem(AUTH_TOKEN);

    this.instance = axios.create({
      baseURL: baseUrl ?? "",
      headers: {
        ...(token && { Authorization: `Bearer ${token as string}` }),
      },
    });

    this._initializeResponseInterceptor();
  }

  private readonly _initializeResponseInterceptor = (): number =>
    this.instance.interceptors.response.use(
      this._handleResponse,
      this._handleError
    );

  private readonly _handleResponse = (response: AxiosResponse): AxiosResponse =>
    response;

  protected _handleError = (error: any): Promise<any> | void => {
    if (401 === error.response.status && hasTokens()) {
      removeTokens();

      window.location.href = "/acesso";
    } else {
      return Promise.reject(error);
    }
  };

  async auth(data: AuthParams): Promise<any> {
    try {
      return await this.instance.post("company/auth/sign_in", data);
    } catch (error) {
      if (error.response.status === 401) {
        throw new InvalidCredentials();
      }

      throw error;
    }
  }

  // async showTerm(): Promise<any> {
  //   try {
  //     return await this.instance.get("/v1/manager/term", {
  //       headers: getHeaders(),
  //     })
  //   } catch (error) {
  //     console.log(error);
  //     throw error;
  //   }
  // }

  // async acceptTerm(term_id: string, ip: string): Promise<any> {
  //   try {
  //     await this.instance.post(`v1/manager/term/${term_id}`, { ip: ip }, {
  //       headers: getHeaders()
  //     })
  //   } catch (error) {
  //     console.log(error);
  //     throw error;
  //   }
  // }

  async listCollaborators(): Promise<any> {
    try {
      const { data } = await this.instance.get(`v1/companies/employees`, {
        headers: getHeaders(),
      });

      return data;
    } catch (error) {
      throw error;
    }
  }

  async getCollaborator(id: string): Promise<any> {
    try {
      const { data } = await this.instance.get(`v1/companies/employees/${id}`, {
        headers: getHeaders(),
      });

      return data;
    } catch (error) {
      throw error;
    }
  }

  async createCollaborator(form: Partial<Collaborator>): Promise<any> {
    try {
      const { data } = await this.instance.post(
        `v1/companies/employees`,
        form,
        {
          headers: getHeaders(),
        }
      );

      return data;
    } catch (error) {
      throw error;
    }
  }

  async removeCollaborator(id: string): Promise<any> {
    try {
      const { data } = await this.instance.delete(
        `v1/companies/employees/${id}`,
        {
          headers: getHeaders(),
        }
      );

      return data;
    } catch (error) {
      throw error;
    }
  }

  async recoverPassword(email: string, basePath?: string): Promise<any> {
    try {
      const { data } = await this.instance.post("company/auth/password", {
        email,
        redirect_url: `${window.location.origin}/${
          basePath ? `${basePath}/` : ""
        }resetar-senha?token=`,
      });

      return data;
    } catch (error) {
      throw error;
    }
  }

  async resetPassword(form: PasswordResetForm): Promise<any> {
    try {
      const { data } = await this.instance.put("company/auth/password", form);

      return data;
    } catch (error) {
      throw error;
    }
  }

  async cancelSubscription(): Promise<any> {
    try {
      const { data } = await this.instance.delete("v1/companies", {
        headers: getHeaders(),
      });

      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateCompany(form: Partial<UpdateCompany>): Promise<any> {
    try {
      const { data } = await this.instance.patch("v1/companies", form, {
        headers: getHeaders(),
      });

      return data;
    } catch (error) {
      throw error;
    }
  }

  async getCompany(): Promise<any> {
    try {
      const { data } = await this.instance.get("v1/companies", {
        headers: getHeaders(),
      });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async listAddresses(): Promise<any> {
    try {
      const { data } = await this.instance.get("v1/companies/addresses", {
        headers: getHeaders(),
      });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getAddress(id: string): Promise<any> {
    try {
      const { data } = await this.instance.get(`v1/companies/addresses/${id}`, {
        headers: getHeaders(),
      });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async registerAddress(form: Company): Promise<any> {
    try {
      const { data } = await this.instance.post(
        "v1/companies/addresses",
        form,
        {
          headers: getHeaders(),
        }
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateAddress(
    form: { address: Partial<Address> },
    id: string
  ): Promise<any> {
    try {
      const { data } = await this.instance.patch(
        `v1/companies/addresses/${id}`,
        form,
        {
          headers: getHeaders(),
        }
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async removeAddress(id: string): Promise<any> {
    try {
      const { data } = await this.instance.delete(
        `v1/companies/addresses/${id}`,
        {
          headers: getHeaders(),
        }
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getPartner(partner: string): Promise<any> {
    try {
      const { data } = await this.instance.get(`v1/public/partners/${partner}`);
      return data;
    } catch (error) {
      throw error;
    }
  }
}

