import { createContext } from 'react';

export interface Contributor {
  birthdate: string
  document_number: string
  email: string
  id: string
  name: string
  phone_number: string
}

interface CompanyContext {
  setContributor(company: Partial<Contributor>): void,
  currentContributor: Partial<Contributor> | null,
}

export default createContext<CompanyContext>({
  setContributor(contributor: Partial<Contributor>) {},
  currentContributor: null,
});
