import React from "react";
import get from "lodash.get";
import { useFormContext } from "react-hook-form";

type NativeProps = React.InputHTMLAttributes<HTMLInputElement>;

type InputProps = {
  name: string;
  label?: string;
  containerClass?: string;
} & NativeProps;

function Checkbox(props: InputProps): JSX.Element {
  const { register, errors } = useFormContext();  

  const {
    label,
    id,
    name,
    required,
    containerClass,
  } = props;
  const error = get(errors, props.name);

  return (
    <section className={`flex items-center ${containerClass ?? ""}`}>
      <input {...props} id={id || name} type="checkbox" ref={register} />
      {label && (
        <label htmlFor={id || name} className="block text-base ml-3">
          {label} {required && <span className="text-red">*</span>}
        </label>
      )}
      {error && <span className="text-red">{error.message}</span>}
    </section>
  );
};

export default Checkbox;
