import { AUTH_TOKEN, AUTH_USER, AUTH_CLIENT } from "@utils/constants";

export const hasTokens = () => {
  return (
    localStorage.getItem(AUTH_TOKEN) &&
    localStorage.getItem(AUTH_USER) &&
    localStorage.getItem(AUTH_CLIENT)
  );
};
export const removeTokens = () => {
  localStorage.removeItem(AUTH_TOKEN);
  localStorage.removeItem(AUTH_USER);
  localStorage.removeItem(AUTH_CLIENT);
};
