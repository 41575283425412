import React from "react";
import Modal from "@components/layout/Modal";
import CompanyForm from './forms/Company';
import AddressForm from './forms/Address';
import SubscriptionForm from './forms/Subscription';
import { useCompany } from "@hooks";

type ModalProps = {
  onClose: () => void;
  isOpen: boolean;
};

const EditUser = ({ onClose, isOpen = false }: ModalProps) => {
  const { 
    showningPersonalDataForm,
    showningAddressForm,
    showningPaymentForm
  } = useCompany();

  const title = (
    (showningPersonalDataForm && 'Alterar dados de acesso') || 
    (showningPaymentForm && 'Alterar dados de assinatura') ||
    (showningAddressForm && 'Alterar dados de endereço')
  );

  return (
    <Modal
      title={title}
      onClose={onClose}
      isOpen={isOpen}
    >
      {showningPersonalDataForm && <CompanyForm onClose={onClose} />}
      {showningAddressForm && <AddressForm onClose={onClose} />}
      {showningPaymentForm && <SubscriptionForm onClose={onClose} />}
    </Modal>
  );
};

export default EditUser;
