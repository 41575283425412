import React from 'react'
import styled from 'styled-components'

const Container = styled.section`
	display: flex;
	align-items: center;
	justify-content: center;
`

const Spinner = styled.svg`
	animation: rotate 2s linear infinite;
	width: 20px;
	height: 20px;

	& .path {
		stroke-linecap: round;
		animation: dash 1.5s ease-in-out infinite;
	}

	@keyframes rotate {
		100% {
			transform: rotate(360deg);
		}
	}

	@keyframes dash {
		0% {
			stroke-dasharray: 1, 150;
			stroke-dashoffset: 0;
		}
		50% {
			stroke-dasharray: 90, 150;
			stroke-dashoffset: -35;
		}
		100% {
			stroke-dasharray: 90, 150;
			stroke-dashoffset: -124;
		}
	}
`

const Loading = (): JSX.Element => (
	<Container>
		<Spinner viewBox="0 0 50 50">
			<circle
				className="path"
        stroke="currentColor"
				cx="25"
				cy="25"
				r="20"
				fill="none"
				strokeWidth="4"
			/>
		</Spinner>
	</Container>
)

export default Loading
