import React, { useState } from "react";
import Header from "@components/layout/Header";
import Footer from "@components/layout/Footer";
import Success from "./components/Success";
import Form, { FormData } from "./components/Form";
import styled from "styled-components";
import { useQuery } from "@utils/url";
import { useApi } from "@hooks";
import { useHistory } from "react-router";

const Main = styled.main`
  height: calc(100% - 210px);
`;

export const Index = (): JSX.Element => {
  const params = useQuery();
  const [success, setSuccess] = useState(false);
  const token = params.get('token') as string;
  const api = useApi();
  const history = useHistory();

  if (!token) {
    history.push('/acesso');
  }

  const onSubmit = async (data: FormData) => {
    await api.resetPassword({
      ...data,
      reset_password_token: token,
    });
    
    setSuccess(true);
  };

  return (
    <>
      <Header />
      <Main className="flex justify-center items-center">
        <section className="shadow w-1/3 rounded-3xl bg-white p-6">
          {!success && <Form submitHandler={onSubmit} />}
          {success && <Success />}
        </section>
      </Main>
      <Footer />
    </>
  );
};
