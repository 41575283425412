import { useContext } from 'react'
import { ApiContext, CompanyContext, ContributorContext, ToastContext } from '@contexts'
import { useForm as useFormHook } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { AnyObjectSchema } from 'yup'

export const useApi = () => useContext(ApiContext)
export const useCompany = () => useContext(CompanyContext)
export const useContributor = () => useContext(ContributorContext)
export const useToast = () => useContext(ToastContext)

type useFormCustomProps = {
  schema?: AnyObjectSchema
  defaultValues?: any
}

export const useForm = (props: useFormCustomProps = {}) => {
  return useFormHook({
    defaultValues: props.defaultValues,
    mode: "onChange",
    ...(props.schema && { resolver: yupResolver(props.schema) }),
  });
}
