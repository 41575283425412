import React, { ReactNode } from "react";
import { Transition } from "@headlessui/react";

type ModalProps = {
  onClose: () => void;
  isOpen: boolean;
  children: ReactNode;
  title: ReactNode;
  footer?: ReactNode;
};

const Modal = ({
  onClose,
  footer,
  title,
  children,
  isOpen = false,
}: ModalProps) => {
  return (
    <Transition show={isOpen}>
      <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            className="fixed inset-0"
            enter="transition-opacity duration-75"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            aria-hidden="true"
          >
            <div className="absolute inset-0 bg-gray-500 opacity-75" />
          </Transition.Child>
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            ​
          </span>
          <Transition.Child
            className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl w-full"
            enter="ease-out duration-30"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div>
              <div>
                <h3
                  className={`text-xl px-6 sm:px-12 py-5 flex justify-between leading-6 font-bold text-black-100 border-b border-gray-100`}
                  id="modal-headline"
                >
                  {title}
                  <button onClick={onClose}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="w-6 text-black-40"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </h3>
                {children}
              </div>
            </div>
            {footer && (
              <div className="p-4 pt-0 flex justify-end">{footer}</div>
            )}
          </Transition.Child>
        </div>
      </div>
    </Transition>
  );
};

export default Modal;
