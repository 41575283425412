import React, { useState } from "react";
import { Form, Input } from "@components/form";
import { useApi, useCompany } from "@hooks";
import FirstAccess from "@components/modals/FirstAccess";
import InvalidCredentials from "@errors/InvalidCredentials";
import { AUTH_TOKEN, AUTH_USER, AUTH_CLIENT } from "@utils/constants";
import { useHistory } from "react-router";
import Loader from "@components/layout/Loader";
import { useToast } from "@hooks";

type SigninProps = {
  onRecoverPassword: () => void;
};

type SigninData = {
  email: string;
  password: string;
};

const Signin = ({ onRecoverPassword }: SigninProps): JSX.Element => {
  const history = useHistory();
  const toast = useToast();
  const api = useApi();
  const { setCompany, logo, basePath } = useCompany();
  const [isLoading, setLoading] = useState(false);
  const [isInvalidData, setIsInvalidData] = useState(false);
  const [email, setEmail] = useState("");
  const [first, setFirst] = useState(false);

  const handleSubmit = async (form: SigninData) => {
    try {
      const { headers, data } = await api.auth(form);

      setLoading(true);

      localStorage.setItem(AUTH_TOKEN, headers["access-token"]);
      localStorage.setItem(AUTH_CLIENT, headers.client);
      localStorage.setItem(
        AUTH_USER,
        JSON.stringify({
          id: data.id,
          name: data.name,
          document_number: data.document_number,
          email: data.email,
          document_type: data.document_type,
          status: data.status,
          slots: data.slots,
          owner_id: data.owner_id,
          partner_id: data.partner_id,
        })
      );

      if (data.status !== "canceled") {
        const company = await api.getCompany();

        setCompany(company);

        setLoading(false);
        setIsInvalidData(false);

        history.push("/");
      } else {
        setIsInvalidData(true);
      }
    } catch (error) {
      if (error instanceof InvalidCredentials) {
        setIsInvalidData(true);
      }
    }
  };

  const recoverPassword = async () => {
    if (email !== "") {
      await api.recoverPassword(email, basePath);
      onRecoverPassword();
    } else {
      toast.error(
        "Algo deu errado!",
        "Favor digitar seu e-mail para envio das informações."
      );
    }
  };

  const firstAccess = async () => {
    if (email !== "") {
      try {
        setFirst(true);
      } catch (err) {
        toast.error("Algo deu errado!", "E-mail não encontrado.");
      }
    } else {
      toast.error(
        "Algo deu errado!",
        "Favor digitar seu e-mail para envio das informações."
      );
    }
  };

  return (
    <div className="p-12 bg-white flex justify-center items-center flex-wrap h-full w-full">
      <div className="w-full">
        <figure className="mb-16 flex justify-center">
          <img width="157px" alt="logo" src={logo} />
        </figure>

        <div>
          <Form onSubmit={handleSubmit}>
            <Input
              containerClass="pb-5"
              name="email"
              label="Meu Email"
              type="email"
              placeholder="Digite"
              required
              onChange={({ target }) => setEmail(target.value)}
            />
            <Input
              name="password"
              label="Senha"
              type="password"
              placeholder="Digite"
              required
            />
            <div className="mt-5 flex justify-between flex-col">
              <p className="text-primary font-bold">Primeiro acesso?</p>
              <button
                type="button"
                onClick={firstAccess}
                className="text-gray-400 focus:outline-none font-bold"
              >
                Digite seu e-mail e clique aqui?
              </button>
            </div>

            <div className="mt-5 flex justify-between">
              <button
                type="button"
                onClick={recoverPassword}
                className="text-primary focus:outline-none font-bold"
              >
                Esqueceu sua senha?
              </button>
            </div>

            {isInvalidData ? (
              <div className="bg-primary bg-opacity-5 mt-12 px-5 py-4 rounded-lg text-primary">
                Login ou senha inválidos
              </div>
            ) : (
              <></>
            )}

            <button className="mt-6 focus:outline-none bg-primary w-full text-black-100 font-bold rounded-full px-4 py-2">
              {isLoading ? <Loader /> : "Entrar"}
            </button>
          </Form>
        </div>
      </div>
      <FirstAccess onClose={() => setFirst(false)} isOpen={first} />
    </div>
  );
};

export default Signin;
