import React, { useState } from "react";
import Modal from "@components/layout/Modal";
import { useApi, useToast } from "@hooks";
import { useCompany, useContributor } from '@hooks';
import Loading from "@components/layout/Loading";

type ModalProps = {
  onClose: () => void;
  isOpen: boolean;
};

const RecoverPassword = ({ onClose, isOpen = false }: ModalProps) => {
  const api = useApi();
  const { loadCompany } = useCompany();
  const { currentContributor } = useContributor();
  const toast = useToast();
  const [isLoading, setLoading] = useState<boolean>(false);

  const removeCollaborator = async () => {
    try {
      if (currentContributor) {
        setLoading(true);
        await api.removeCollaborator(currentContributor.id as string);
        onClose();
        await loadCompany();
        setLoading(false);
        toast.success('Sucesso!', 'Colaborador removido');
      }
    } catch (error) {
      toast.error('Falha ao remover colaborador 😐', 'Por favor, tente novamente');
    }
  }

  const Footer = () => (
    <div className="sm:space-x-4 space-y-4 sm:space-y-0 items-center w-full justify-end flex flex-wrap sm:flex-nowrap">
      <button
        onClick={removeCollaborator}
        type="button"
        className="inline-flex focus:outline-none whitespace-nowrap justify-center rounded-full border border-transparent px-4 py-2 bg-primary bg-opacity-5 text-base font-medium text-black-100 focus:outlinenone focus:ring-2 focus:ring-offset-2 focus:ring-orange sm:text-sm w-auto"
      >
        {isLoading ? <Loading />: 'Sim, remover agora'}
      </button>
      <button
        onClick={onClose}
        type="button"
        className="inline-flex focus:outline-none justify-center rounded-full border border-transparent px-4 py-2 bg-primary text-base font-medium text-black-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange sm:text-sm w-auto"
      >
        Não, quero manter
      </button>
    </div>
  );

  return (
    <Modal
      footer={<Footer />}
      title="Remover colaborador"
      onClose={onClose}
      isOpen={isOpen}
    >
      <div className="p-12">
        <p className="text-base text-gray-500">
          Você tem certeza? Ao confirmar, <b>{currentContributor?.name}</b> não
          conseguirá mais usar os nossos benefícios.
        </p>
      </div>
    </Modal>
  );
};

export default RecoverPassword;
