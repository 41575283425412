import React, { useEffect, useState, useCallback } from "react";
import { useCompany } from "@hooks";
import { Address } from "@contexts/Api";

const PersonalData = () => {
  const { showAddressForm } = useCompany();
  const [address, setAddress] = useState<Address>();
  const { company } = useCompany();

  const colectAddress = useCallback(async () => {
    try {
      const data = company?.address ? company?.address : [];
      setAddress(data[0]);
    } catch (error) {
      throw error;
    }
  }, [company]);

  useEffect(() => {
    colectAddress();
  }, [colectAddress, company]);

  return (
    <section className="w-full my-12">
      <h4 className="text-2xl text-center sm:text-left text-black-100 font-bold mb-10">
        Dados de endereço
      </h4>

      <div className="shadow-md w-full rounded-3xl bg-white p-6">
        <div className="w-full sm:px-5">
          <div className="flex mb-2">
            <div className="w-1/2 text-base text-black-100 font-bold">CEP</div>
            <div className="w-1/2 text-right text-black-70">
              {address?.zipcode ?? "-"}
            </div>
          </div>
          <div className="flex mb-2">
            <div className="w-1/2 text-base text-black-100 font-bold">
              Endereço
            </div>
            <div className="w-1/2 text-right text-black-70">
              {address?.street}
            </div>
          </div>
          <div className="flex mb-2">
            <div className="w-1/2 text-base text-black-100 font-bold">
              Bairro
            </div>
            <div className="w-1/2 text-right text-black-70">
              {address?.neighborhood}
            </div>
          </div>
          <div className="flex mb-2">
            <div className="w-1/2 text-base text-black-100 font-bold">
              Complemento
            </div>
            <div className="w-1/2 text-right text-black-70">
              {address?.complement}
            </div>
          </div>
          <div className="flex mb-2">
            <div className="w-1/2 text-base text-black-100 font-bold">
              Cidade
            </div>
            <div className="w-1/2 text-right text-black-70">
              {address?.city}
            </div>
          </div>
          <div className="flex mb-2">
            <div className="w-1/2 text-base text-black-100 font-bold">
              Estado
            </div>
            <div className="w-1/2 text-right text-black-70">
              {address?.state}
            </div>
          </div>
        </div>

        <button
          onClick={() => showAddressForm(true)}
          className="bg-primary bg-opacity-5 focus:outline-none mt-5 w-full py-3 rounded-full text-primary-70"
        >
          Alterar
        </button>
      </div>
    </section>
  );
};

export default PersonalData;

