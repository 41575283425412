import React, { useState } from "react";
import styled from "styled-components";
import { Transition } from "@headlessui/react";
import cntl from 'cntl';
import { removeTokens } from "@utils/auth";
import { useHistory } from "react-router";
import { useCompany } from "@hooks";

const buttonCN = ({ active }: { active: boolean }) => cntl`
  inline 
  sm:hidden 
  p-2 
  rounded-lg 
  text-white
  focus:outline-none

  ${
    active ? cntl`bg-primary bg-opacity-70` : cntl`bg-primary`
  }
`;

const Logo = styled.img`
  width: 100px;
`;

const Header = (): JSX.Element => {
  const { company, logo } = useCompany();
  const history = useHistory()
  const [opened, setOpened] = useState(false);

  const logout = () => {
    removeTokens();

    history.push('/acesso');
  }

  return (
    <header className="bg-white w-full h-20 shadow-sm px-4 fixed sm:px-0 sm:relative top-0">
      <section className="flex w-full max-w-720 mx-auto items-center justify-between h-full">
        <Logo src={logo} />

        <button onClick={() => setOpened(!opened)} className={buttonCN({ active: opened })}>
          <svg
            className="w-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16m-7 6h7"
            />
          </svg>
        </button>

        <nav className="hidden sm:flex items-center">
          <div className="text-right">
            <p className="text-black-40 font-normal">acessando como</p>
            <p className="text-black-100 font-bold">{company?.name}</p>
          </div>

          <button onClick={logout} className="bg-black-100 focus:outline-none text-white text-base font-bold px-4 py-2 ml-4">
            Sair
          </button>
        </nav>
      </section>
      <Transition 
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        show={opened}>
        <nav className="fixed top-0 left-0 w-full mt-20 bg-white p-4 flex justify-between items-center">
          <div className="sm:text-right">
            <p className="text-black-40 font-normal">acessando como</p>
            <p className="text-black-100 font-bold">{company?.name}</p>
          </div>

          <button onClick={logout} className="bg-black-100 focus:outline-none text-white text-base font-bold px-4 py-2 ml-4">
            Sair
          </button>
        </nav>
      </Transition>
    </header>
  );
};
export default Header;
