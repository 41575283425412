import React, { useCallback, useEffect, useState } from "react";
import Notification from "@components/layout/Notification";
import ToastContext from "@contexts/Toast";

type ToastProvider = {
  children: JSX.Element;
};

type Toast = {
  id?: number
  type: "error" | "success";
  title: string;
  message: string;
};

export default function ToastContextProvider({ children }: ToastProvider) {
  const [toasts, setToasts] = useState<Toast[]>([]);

  useEffect(() => {
    if (toasts.length > 0) {
      const timer = setTimeout(
        () => setToasts((toasts) => toasts.slice(1)),
        6000
      );
      return () => clearTimeout(timer);
    }
  }, [toasts]);

  const addToast = useCallback(
    function (toast: Toast) {
      setToasts((toasts) => [...toasts, { ...toast, id: Date.now() }]);
    },
    [setToasts]
  );

  const error = (title: string, message: string) =>
    addToast({ type: "error", title, message });
  const success = (title: string, message: string) =>
    addToast({ type: "success", title, message });

  return (
    <ToastContext.Provider value={{ error, success }}>
      {children}
      <div className="toasts-wrapper">
        {toasts.map((toast) => (
          <Notification
            key={toast.id}
            open={true}
            onClose={() => setToasts((toasts) => toasts.slice(1))}
            title={toast.title}
            message={toast.message}
            variant={toast.type}
          />
        ))}
      </div>
    </ToastContext.Provider>
  );
}
