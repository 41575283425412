import { AUTH_USER } from '@utils/constants';

type User = {
  id: string
  uid: string,
  name: string,
  acquired_spaces: number
} | null

export const useSession = (): User => {
  const info = localStorage.getItem(AUTH_USER);
  
  return info ? JSON.parse(info) : null;
}
