import React from 'react'
import Header from '@components/layout/Header';
import Footer from '@components/layout/Footer';
import Success from './components/Success';
import styled from 'styled-components';

const Main = styled.main`
  height: calc(100% - 210px)
`;

export const Index = (): JSX.Element => {
  return (
    <>
      <Header />
      <Main className="flex justify-center items-center">
        <section className="shadow w-1/3 rounded-3xl bg-white p-6">
          <Success />
        </section>
      </Main>
      <Footer />
    </>
  )
}
