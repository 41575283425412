import React, { useState } from "react";
import { Form, Input } from "@components/form";
import { useCompany } from "@hooks";
// import { toDisplayDate, toStoreDate } from "@helpers/format";
import * as yup from "yup";
// import {
//   isBirthdateValid,
//   isDocumentNumberValid,
//   isPhoneValid,
// } from "@utils/validations";
import { Company } from "@contexts/Api";
// import { unMask, UNMASKS } from "@helpers/unmask";
import { useApi, useToast } from "@hooks";
import Loading from "@components/layout/Loading";

const schema = yup.object().shape({
  // name: yup.string().required("Prencha o nome completo"),
  password: yup.string(),
  password_confirmation: yup
    .string()
    .when("current_password", (value: any, schema: any) => {
      return value
        ? schema
            .required("Confirme sua senha")
            .oneOf([yup.ref("password"), null], "As senhas não são iguais")
        : schema;
    }),
  // manager_attributes: yup.object().shape({
  //   current_password: yup.string(),
  //   document_number: yup
  //     .string()
  //     .required("Preencha o cpf")
  //     .test("is-cpf", "cpf não é válido", (value) =>
  //       isDocumentNumberValid(value as string)
  //     ),
  //   phone_number: yup
  //     .string()
  //     .required("Preencha o celular")
  //     .test("is-cpf", "celular não é válido", (value) =>
  //       isPhoneValid(value as string)
  //     ),
  //   birthdate: yup
  //     .string()
  //     .required("Preencha a data de nascimento")
  //     .test("is-bithdate", "data de nascimento inválida", (value) =>
  //       isBirthdateValid(value as string)
  //     ),
  //   email: yup.string().required("Preencha o email").email("Email inválido"),
  // }),
});

type CompanyProps = {
  onClose: () => void;
};

const CompanyForm = ({ onClose }: CompanyProps) => {
  const api = useApi();
  const { company, loadCompany } = useCompany();
  const toast = useToast();
  const [isLoading, setLoading] = useState<boolean>(false);

  const updateManager = async (form: Company) => {
    try {
      setLoading(true);

      if (!form.current_password) {
        delete form.password;
      }

      delete form.current_password;
      delete form.password_confirmation;
      form?.owner_attributes?.phone_number &&
        (form.owner_attributes.phone_number = `+55${form.owner_attributes.phone_number.replace(
          /\D/g,
          ""
        )}`);

      // form.manager_attributes = {
      //   ...form.manager_attributes,
      //   id: company?.owner.id,
      //   phone_number: unMask(phone_number as string, UNMASKS.phone),
      //   document_number: unMask(
      //     document_number as string,
      //     UNMASKS.document_number
      //   ),
      //   birthdate: toStoreDate(birthdate as string),
      // };
      await api.updateCompany(form as any);
      await loadCompany();
      toast.success("Sucesso!", "Dados de cadastro alterados com sucesso");
      onClose();
    } catch (error) {
      toast.error("Falha ao alterar os dados 😐", "Por favor, tente novamente");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Form schema={schema} onSubmit={updateManager}>
        {/* <div className="p-6 sm:p-12">
          <Input
            containerClass="pb-5"
            name="name"
            label="Nome completo"
            type="text"
            placeholder="Digite"
            defaultValue={company?.name}
            required
            readOnly
          />

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <Input
              name="manager_attributes.document_number"
              label="Cpf"
              type="text"
              placeholder="Digite"
              mask="999.999.999-99"
              defaultValue={company?.owner.document_number}
              required
              readOnly
            />
            <Input
              name="manager_attributes.birthdate"
              label="Data de nascimento"
              type="text"
              placeholder="Digite"
              mask="99/99/9999"
              readOnly
              required
              defaultValue={toDisplayDate(company?.owner.birthdate)}
            />
            <Input
              name="manager_attributes.phone_number"
              label="Celular"
              type="text"
              placeholder="Digite"
              mask="(99) 99999-9999"
              required
              defaultValue={company?.owner.phone_number}
            />
            <Input
              name="manager_attributes.email"
              label="E-mail"
              type="text"
              placeholder="Digite"
              required
              defaultValue={company?.email}
              readOnly
            />
          </div>
  </div> */}
        <Input
          name="owner_attributes.id"
          type="text"
          required
          defaultValue={company?.owner?.id}
          hidden
        />
        <div className="p-6 sm:p-12">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <Input
              name="owner_attributes.phone_number"
              label="Telefone"
              type="text"
              placeholder="Digite"
              mask="(99) 99999-9999"
              required
              defaultValue={company?.owner?.phone_number?.replace("+55", "")}
            />

            <Input
              name="owner_attributes.email"
              label="E-mail"
              type="text"
              placeholder="Digite"
              required
              defaultValue={company?.email}
              readOnly
            />
          </div>
        </div>
        <h3 className="text-xl p-6 sm:px-12 py-5 flex justify-between leading-6 font-bold text-black-100 border-b border-t border-gray-100 ">
          Você gostaria de alterar a senha também?
        </h3>
        <div className="sm:pl-12 sm:pr-12 sm:pt-6">
          <div className="grid grid-cols-1 sm:grid-cols-1">
            <Input
              name="password"
              label="Senha Atual"
              type="current_password"
              placeholder="Digite"
              required
            />
          </div>
        </div>
        <div className="p-6 sm:p-12">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <Input
              name="password"
              label="Nova senha"
              type="password"
              placeholder="Digite"
              required
            />
            <Input
              name="password_confirmation"
              label="Confirme a nova senha"
              type="password"
              placeholder="Digite"
              required
            />
          </div>
        </div>
        <div className="p-6">
          <button className="bg-primary focus:outline-none w-full text-black-100 font-bold rounded-full px-4 py-2">
            {isLoading ? <Loading /> : "Confirmar e Alterar"}
          </button>
        </div>
      </Form>
    </>
  );
};

export default CompanyForm;
