import React, { Suspense } from "react";
import { Loader } from "./@components/layout";
import ApiService from "./@services/api";
import { ApiContext } from "./@contexts";
import { CompanyProvider, ContributorProvider, ToastProvider } from "@providers";
import Routes from "./routes";

interface RoutesProps {
  base: string;
}

const baseUrl = `${process.env.REACT_APP_API}/api`;

const App = ({ base }: RoutesProps): JSX.Element => {
  if (!base) {
    return <></>;
  }

  return (
    <Suspense fallback={<Loader />}>
      <ToastProvider>
        <ApiContext.Provider value={new ApiService({ baseUrl })}>
          <CompanyProvider basePath={base}>
            <ContributorProvider>
              <Routes base={base} />
            </ContributorProvider>
          </CompanyProvider>
        </ApiContext.Provider>
      </ToastProvider>
    </Suspense>
  );
};

export default App;
