import React from "react";
import get from "lodash.get";
import InputMask from "react-input-mask";
import { useFormContext, Controller } from "react-hook-form";

type NativeProps = React.InputHTMLAttributes<HTMLInputElement>;

type InputProps = {
  name: string;
  mask?: string;
  label?: string;
  containerClass?: string;
} & NativeProps;

function Input(props: InputProps): JSX.Element {
  const { register, errors, control } = useFormContext();

  const {
    label,
    mask,
    id,
    name,
    required,
    containerClass,
    placeholder,
  } = props;
  const error = get(errors, props.name);
  const inputCommonProps = {
    ...props,
    className: `${props.className ?? ""} w-full bg-gray-100 border p-4 ${
      props.type !== "checkbox" ? "h-14" : ""
    } rounded-md ${
      props.disabled && "opacity-50"
    } focus:border-gray-200 focus:bg-white focus:outline-none ${
      typeof error !== "undefined" && "border-red"
    }`,
  };

  delete inputCommonProps.containerClass;
  delete inputCommonProps.required;

  return (
    <section className={`${containerClass ?? ""}`}>
      {label && (
        <label htmlFor={id || name} className="block text-base mb-3">
          {label} {required && <span className="text-red">*</span>}
        </label>
      )}
      {mask ? (
        <Controller
          render={({ value, onChange }) => {
            return (
              <InputMask
                value={value ?? props.defaultValue}
                mask={mask}
                readOnly={inputCommonProps.readOnly}
                placeholder={placeholder}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  inputCommonProps.onChange?.(event);
                  onChange(event.target.value);
                }}
                className={inputCommonProps.className}
              />
            );
          }}
          name={name}
          control={control}
          defaultValue={inputCommonProps.defaultValue}
        />
      ) : (
        <input {...inputCommonProps} ref={register} />
      )}

      {error && <p className="text-red mt-1 text-sm">{error.message}</p>}
    </section>
  );
}

export default Input;
