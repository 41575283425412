import React, { useState } from "react";
import { unMask, UNMASKS } from '@helpers/unmask';
import { toStoreDate } from '@helpers/format';
import Modal from "@components/layout/Modal";
import { Form, Input } from "@components/form";
import { Loading } from "@components/layout";
import * as yup from "yup";
import {
  isBirthdateValid,
  isDocumentNumberValid,
  isPhoneValid,
} from "@utils/validations";
import { useCompany, useApi, useToast } from "@hooks";

const schema = yup.object().shape({
  name: yup.string().required("Prencha o nome completo"),
  document_number: yup
    .string()
    .required("Preencha o cpf")
    .test("is-cpf", "cpf não é válido", (value) =>
      isDocumentNumberValid(value as string)
    ),
  phone_number: yup
    .string()
    .required("Preencha o celular")
    .test("is-cpf", "celular não é válido", (value) =>
      isPhoneValid(value as string)
    ),
  birthdate: yup
    .string()
    .required("Preencha a data de nascimento")
    .test("is-bithdate", "data de nascimento inválida", (value) =>
      isBirthdateValid(value as string)
    ),
  email: yup.string().required("Preencha o email").email("Email inválido"),
});

type ModalProps = {
  onClose: () => void;
  isOpen: boolean;
};

const defaultValues = {
  name: '',
  document_number: '',
  birthdate: '',
  phone_number: '',
  email: '',
}

const NewUser = ({ onClose, isOpen = false }: ModalProps) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const api = useApi();
  const toast = useToast();
  const { loadCompany } = useCompany();
  
  const handleSubmit = async (form: any, { reset }: any) => {
    try {
      setLoading(true);
      await api.createCollaborator({ 
        ...form,
        birthdate: toStoreDate(form.birthdate),
        document_number: unMask(form.document_number, UNMASKS.document_number),
        phone_number: unMask(form.phone_number, UNMASKS.phone),
      });
      await loadCompany();
      reset();
      toast.success('Sucesso!', 'colaborador cadastrado');
    } catch (error) {
      toast.error('Falha ao cadastrar colaborador', 'Por favor, tente novamente');
    } finally {
      setLoading(false);
      onClose();
    }
  };

  return (
    <Modal title="Dados para cadastro" onClose={onClose} isOpen={isOpen}>
      <div className="px-5 pt-12 pb-5">
        <Form defaultValues={defaultValues} schema={schema} onSubmit={handleSubmit}>
          <Input
            containerClass="pb-5"
            name="name"
            label="Nome completo"
            type="text"
            placeholder="Digite"
            required
          />

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <Input
              name="document_number"
              label="Cpf"
              type="text"
              placeholder="Digite"
              mask="999.999.999-99"
              required
            />
            <Input
              name="birthdate"
              label="Data de nascimento"
              type="text"
              placeholder="Digite"
              mask="99/99/9999"
              required
            />
            <Input
              name="phone_number"
              label="Celular"
              mask="(99) 99999-9999"
              type="text"
              placeholder="Digite"
              required
            />
            <Input
              name="email"
              label="E-mail"
              type="text"
              placeholder="Digite"
              required
            />
          </div>

          <button className="mt-16 focus:outline-none bg-primary w-full text-black-100 font-bold rounded-full px-4 py-2">
            {isLoading ? <Loading /> : 'Concordar e Cadastrar'}
          </button>
        </Form>
      </div>
    </Modal>
  );
};

export default NewUser;
