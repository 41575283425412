import { unMask } from '../@helpers/unmask';

export const isDocumentNumberValid = (number: string): boolean => {
  number = number.replace(/\D/g, '');
  
  if (number.toString().length !== 11 || /^(\d)\1{10}$/.test(number)) return false;

  let result = true;
  
  [9, 10].forEach((j) => {
    let sumCpf = 0, rest;

    number.split(/(?=)/).splice(0, j).forEach((digit, i) => {
      sumCpf += parseInt(digit) * ((j + 2) - (i + 1));
    });

    rest = sumCpf % 11;
    rest = (rest < 2) ? 0 : 11 - rest;

    if (rest.toString() !== number.substring(j, j + 1)) result = false;
  });

  return result;
};

export const isBirthdateValid = (date: string): boolean => {
  if (!date) {
    return false;
  }
  
  const [d, m, y]: any = date.split('/').map(Number)
  const dateValide: any = Number(new Date().getFullYear() - y);

  const isUp18 = dateValide.toString().match(/^[1-9]?[0-9]{1}$|^100$/) >= 18;
  const validDay = d >= 1 && d <= 31;
  const validMonth = m >= 1 && m <= 12;

  if (isUp18 && validDay && validMonth) return true
  return false
}

export const isPhoneValid = (number: string): boolean => {
  const unmaskedValue = unMask(number, /\(|\)|-|_| /g);
  const uniqueValues = Array.from(new Set(unmaskedValue.split('')));

  if (unmaskedValue.length === 11 && uniqueValues.length > 1) return true
  return false
}

export const isZipCodeValid = (number: string): boolean => {
  const unmaskedValue = unMask(number, /-/g);
  const uniqueValues = Array.from(new Set(unmaskedValue.split('')));

  if (unmaskedValue.length === 8 && uniqueValues.length > 1) return true
  return false
}

export const isVisa = (number: string) => {
  const cardNumber = number.replace(/\s/g, "")
  if (cardNumber.match(/^4[0-9]{12}(?:[0-9]{3})/)) return true
  return false
};
