import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import './style.css'
import './i18n'

const Render = (): JSX.Element => {
  const partner = window.location.pathname.split('/')[1];

	return (
		<BrowserRouter basename={partner}>
			<App base={partner} />
		</BrowserRouter>
	)
}

ReactDOM.render(
	<Render />,
	document.getElementById('root') as HTMLElement
)
