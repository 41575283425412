import React, { useEffect, useCallback, useState } from "react";
import CompanyContext, { Address, Root } from "../@contexts/Company";
import { useApi, useToast } from "@hooks";
import { useSession } from "@helpers/user";
import { Contributor } from "@contexts/Contributor";

let root = document.documentElement;

interface ProviderProps {
  children: JSX.Element;
  basePath: string;
}

export const CompanyProvider = ({
  children,
  basePath,
}: ProviderProps): JSX.Element => {
  const [company, setCompany] = useState<Root | null>(null);
  const [isLoadingCompany, setLoading] = useState<boolean>(true);
  const [isLoadingTheme, setLoadingTheme] = useState<boolean>(true);

  const api = useApi();
  const toast = useToast();
  const user = useSession();
  const [showningPersonalDataForm, showPersonalDataForm] =
    useState<boolean>(false);
  const [showningPaymentForm, showPaymentForm] = useState<boolean>(false);
  const [showningAddressForm, showAddressForm] = useState<boolean>(false);
  const [showningCancelSubscriptionModal, showCancelSubscriptionModal] =
    useState<boolean>(false);
  const [logo, setLogo] = useState<string>("");

  const loadTheme = useCallback(async () => {
    try {
      setLoadingTheme(true);
      const { metadata } = await api.getPartner(basePath);
      root.style.setProperty("--primary", metadata.color);
      setLogo(metadata.image_logo);
    } catch (err) {
      toast.error(`Falha ao buscar parceiro 😐`, "Por favor, tente novamente.");
    } finally {
      setLoadingTheme(false);
    }
  }, [api, basePath, toast]);

  const loadCompany = useCallback(async () => {
    if (user) {
      setLoading(true);
      const contributors: Contributor[] = await api.listCollaborators();
      const address: Address[] = await api.listAddresses();
      const res: Root = await api.getCompany().catch(() => {
        setLoading(false);
      });
      setCompany({
        ...res,
        contributors: contributors,
        address: address,
      });
      setLoading(false);
    }
  }, [api, user]);

  useEffect(() => {
    if (basePath) {
      loadTheme();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basePath]);

  return (
    <CompanyContext.Provider
      value={{
        basePath,
        company,
        logo,
        showningPersonalDataForm,
        showningPaymentForm,
        showningAddressForm,
        showningCancelSubscriptionModal,
        isLoadingCompany,
        isLoadingTheme,
        setCompany,
        loadCompany,
        showPaymentForm,
        showPersonalDataForm,
        showAddressForm,
        showCancelSubscriptionModal,
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
};

export default CompanyProvider;
