import React from "react";
import { FormProvider, UseFormMethods } from "react-hook-form";
import * as yup from "yup";
import { useForm } from "@hooks";

interface FormProps {
  id?: string;
  onSubmit: Function;
  children: JSX.Element | JSX.Element[];
  defaultValues?: object;
  form?: UseFormMethods<Record<string, any>>;
  schema?: yup.AnyObjectSchema;
}

const Form = ({
  id,
  onSubmit,
  children,
  defaultValues,
  schema,
  form,
  ...rest
}: FormProps): JSX.Element => {
  const defaultForm = useForm({
    defaultValues,
    schema,
  });

  const methods = form || defaultForm;
  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit((data) => onSubmit(data, methods))}
        {...rest}
      >
        {children}
      </form>
    </FormProvider>
  );
};

export default Form;
