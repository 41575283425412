import React from 'react'

const Footer = (): JSX.Element => (
	<footer className="bg-gray-100 w-full h-20 py-8">
    <p className="text-base text-center">
      © {`${new Date().getFullYear()} TEM Saúde`}
    </p>
  </footer>
)

export default Footer
