import React, { useState } from "react";
import Modal from "@components/layout/Modal";
import { useApi, useToast } from "@hooks";
import { removeTokens } from "@utils/auth";
import { useHistory } from "react-router";
import Loading from "@components/layout/Loading";

type ModalProps = {
  onClose: () => void;
  isOpen: boolean;
};


const CancelSubscription = ({ onClose, isOpen = false }: ModalProps) => {
  const api = useApi();
  const toast = useToast();
  const history = useHistory();
  const [isLoading, setLoading] = useState<boolean>(false);

  const cancel = async () => {
    try {
      setLoading(true);
      await api.cancelSubscription();
      
      removeTokens();
      
      history.push('/assinatura-cancelada');
    } catch (error) {
      toast.error('Falha ao cancelar a assinatura 😐', 'Por favor, tente novamente');
    } finally {
      setLoading(false);
    }
  }

  const Footer = () => (
    <div className="sm:space-x-4 space-y-4 sm:space-y-0 items-center flex flex-wrap sm:flex-nowrap">
      <button
        onClick={cancel}
        type="button"
        className="block focus:outline-none whitespace-nowrap justify-center rounded-full border border-transparent px-4 py-2 bg-primary bg-opacity-5 text-sm font-medium text-primary-70 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange sm:text-sm"
      >
        {isLoading ? <Loading /> : 'Sim, cancelar'}
      </button>
      <button
        onClick={onClose}
        type="button"
        className="block focus:outline-none whitespace-nowrap justify-center rounded-full border border-transparent px-4 py-2 bg-primary text-sm font-medium text-black-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange sm:text-sm"
      >
        Não, quero continuar
      </button>
    </div>
  );

  return (
    <Modal
      footer={<Footer />}
      title="Cancelar Assinatura"
      onClose={onClose}
      isOpen={isOpen}
    >
      <div className="p-12">
        <p className="text-base text-gray-500 mb-4">
          Você tem certeza disso? 😭
        </p>
        <p className="text-base text-gray-500 mb-4">
          Se você confirmar, você e seus colaboradores não vão conseguir mais usar nossos benefícios.
        </p>
        <p className="text-base text-gray-500">
          Mas claro, sempre que você quiser voltar, é só reativar sua assinatura  😉
        </p>
      </div>
    </Modal>
  );
};

export default CancelSubscription;
