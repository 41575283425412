import React, { useState, useEffect, useCallback } from "react";
import { Form, Input, Select } from "@components/form";
import { useForm, useToast } from "@hooks";
import state from "@utils/enums/states";
import { useApi } from "@hooks";
import Loading from "@components/layout/Loading";
import axios from "axios";
import * as yup from "yup";
import { isZipCodeValid } from "@utils/validations";
import { unMask, UNMASKS } from "@helpers/unmask";

type AddressProps = {
  onClose: () => void;
};

type Address = {
  id: string;
  zipcode: string;
  street: string;
  number: string;
  complement: string;
  neighborhood: string;
  city: string;
  state: string;
};

const schema = yup.object().shape({
  zipcode: yup
    .string()
    .required("Prencha o cep")
    .test("is-cep", "cep não é válido", (value) =>
      isZipCodeValid(value as string)
    ),
  street: yup.string().required("Prencha o endereço completo"),
  neighborhood: yup.string().required("Prencha o bairro"),
  city: yup.string().required("Prencha a cidade"),
  state: yup.string().required("Selecione o estado"),
});

const AddressForm = ({ onClose }: AddressProps) => {
  const api = useApi();
  const toast = useToast();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [address, setAddress] = useState<Address>();
  const [isLoadingAddress, setLoadingAddress] = useState<boolean>(true);
  const form = useForm({ schema });

  const colectAddress = useCallback(async () => {
    try {
      const data = await api.listAddresses();
      setAddress({
        ...data[0],
        zipcode: unMask(data[0].zipcode ?? "", UNMASKS.zipcode),
      });
    } catch (error) {
      throw error;
    } finally {
      setLoadingAddress(false);
    }
  }, [api]);

  useEffect(() => {
    if (address?.id) {
      form.setValue("zipcode", address.zipcode);
      form.setValue("street", address.street);
      form.setValue("neighborhood", address.neighborhood);
      form.setValue("city", address.city);
      form.setValue("state", address.state);
      form.setValue("complement", address.complement);
    }
  }, [address, form]);
  useEffect(() => {
    colectAddress();
  }, [colectAddress]);

  const updateAddress = useCallback(
    async (form: Address) => {
      try {
        setLoading(true);

        const id = address?.id ? address.id : "";

        await api.updateAddress(
          {
            address: form,
          },
          id
        );
        onClose();
        toast.success("Sucesso!", "Dados de endereço alterados com sucesso");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } catch (error) {
        toast.error(
          "Falha ao alterar os dados 😐",
          "Por favor, tente novamente"
        );
      } finally {
        setLoading(false);
      }
    },
    [address, api, onClose, toast]
  );

  const searchCep = async ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const value = target.value.replace(/-|_/g, "");

    if (value.length < 8) {
      return false;
    }

    try {
      const { data } = await axios.get(
        `https://viacep.com.br/ws/${value}/json/`
      );

      form.setValue("zipcode", data.cep);
      form.setValue("street", data.logradouro);
      form.setValue("neighborhood", data.bairro);
      form.setValue("city", data.localidade);
      form.setValue("state", data.uf);
    } catch (error) {
      toast.error(
        "Falha ao buscar o cep digitado 😐",
        "Por favor, tente novamente"
      );
    }
  };

  return (
    <>
      <Form form={form} onSubmit={updateAddress}>
        <div className="p-6 sm:p-12">
          <Input
            containerClass="pb-5"
            name="zipcode"
            label="Cep"
            type="text"
            placeholder="Digite"
            required
            defaultValue={address?.zipcode}
            mask="99999-999"
            onChange={searchCep}
            disabled={isLoadingAddress}
          />

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <Input
              name="street"
              label="Endereço"
              type="text"
              placeholder="Digite"
              required
              defaultValue={address?.street}
              disabled={isLoadingAddress}
            />
            <Input
              name="number"
              label="Número"
              type="text"
              placeholder="Digite"
              required
              defaultValue={address?.number}
              disabled={isLoadingAddress}
            />
            <Input
              name="complement"
              label="Complemento"
              type="text"
              placeholder="Digite"
              defaultValue={address?.complement}
              disabled={isLoadingAddress}
            />
            <Input
              name="neighborhood"
              label="Bairro"
              type="text"
              placeholder="Digite"
              required
              defaultValue={address?.neighborhood}
              disabled={isLoadingAddress}
            />
            <Input
              name="city"
              label="Cidade"
              type="text"
              placeholder="Digite"
              required
              defaultValue={address?.city}
              disabled={isLoadingAddress}
            />
            <Select
              name="state"
              label="Estado"
              placeholder="Digite"
              options={state.map((value) => ({ label: value, value }))}
              required
              defaultValue={address?.state}
              disabled={isLoadingAddress}
            />
          </div>
        </div>
        <div className="p-6">
          <button
            className="bg-primary focus:outline-none w-full text-black-100 font-bold rounded-full px-4 py-2"
            type="submit"
          >
            {isLoading ? <Loading /> : "Confirmar e Alterar"}
          </button>
        </div>
      </Form>
    </>
  );
};

export default AddressForm;

