import React, { useState } from "react";
import { Form, Input, Select } from "@components/form";
import { useApi, useCompany, useToast } from "@hooks";
import { Loading } from "@components/layout";
import * as yup from "yup";
import { isVisa } from "@utils/validations";

type SubscriptionProps = {
  onClose: () => void;
};

const schema = yup.object().shape({
  manager_attributes: yup.object().shape({
    card_number: yup
      .string()
      .test("is-cpf", "O cartão não é um cartão visa", (value) => isVisa(value as string)),
  }),
});

const SubscriptionForm = ({ onClose }: SubscriptionProps) => {
  const { company, loadCompany } = useCompany();
  const api = useApi();
  const toast = useToast();
  const [isLoading, setLoading] = useState<boolean>(false);
  // const expiryMonth = company?.manager.subscription_card.expiryMonth;
  // const expiryYear = String(company?.manager.subscription_card.expiryYear);

  // const expirationMonthYear = `${
  //   (expiryMonth && expiryMonth <= 9 ? "0" : "") + expiryMonth
  // }/${expiryYear.slice(2)}`;

  const updateSubscription = async (form: any) => {
    try {
      setLoading(true);
      const [month, year] = form.expiration_month_year.split("/");
      const { card_number, security_code } = form.manager_attributes;

      delete form.expiration_month_year;

      form.manager_attributes.card_number = Number(card_number.replace(/\s/g, ''));
      form.manager_attributes.security_code = Number(security_code);
      form.manager_attributes.expiration_month = Number(month);
      form.manager_attributes.expiration_year = Number(year);
      form.manager_attributes.id = company?.owner.id;

      await api.updateCompany({
        ...form,
        name: company?.name as string,
      });
      await loadCompany();

      toast.success("Sucesso!", "Dados de assinatura alterados com sucesso");
      onClose();
    } catch (error) {
      toast.error("Falha ao alterar os dados 😐", "Por favor, tente novamente");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Form schema={schema} onSubmit={updateSubscription}>
        <div className="p-6 sm:p-12">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div className="col-span-2">
              <Select
                name="manager_attributes.card_number"
                label="Forma de pagamento"
                placeholder="Digite"
                options={[{ label: "Cartão de Crédito", value: "credit_card" }]}
                required
                defaultValue={company?.owner.phone_number}
              />
            </div>
            <div className="col-span-2">
              <div className="flex items-center text-base mb-3 justify-between w-full">
                <p>
                  Número do cartão
                  <span className="text-red">*</span>
                </p>
                <svg
                  width={39}
                  height={12}
                  viewBox="0 0 39 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20.1699 3.82098C20.1476 5.48586 21.7333 6.415 22.9278 6.96737C24.1551 7.53419 24.5674 7.89762 24.5627 8.40442C24.5533 9.18018 23.5836 9.52249 22.676 9.53583C21.0927 9.55917 20.1722 9.13017 19.4403 8.80564L18.8699 11.3385C19.6042 11.6597 20.9639 11.9398 22.3739 11.952C25.6834 11.952 27.8488 10.4016 27.8605 7.99764C27.8734 4.94683 23.4138 4.7779 23.4443 3.4142C23.4548 3.00076 23.8705 2.55953 24.7817 2.44728C25.2325 2.3906 26.4774 2.34725 27.8886 2.96408L28.4425 0.513433C27.6837 0.251142 26.7081 -3.62396e-05 25.4937 -3.62396e-05C22.3786 -3.62396e-05 20.1874 1.57149 20.1699 3.82098ZM33.7652 0.211131C33.1609 0.211131 32.6515 0.545665 32.4243 1.05913L27.6965 11.772H31.0037L31.6619 10.046H35.7034L36.0851 11.772H39L36.4564 0.211131H33.7652ZM34.2278 3.33418L35.1822 7.67533H32.5683L34.2278 3.33418ZM16.16 0.211131L13.5531 11.772H16.7046L19.3103 0.211131H16.16ZM11.4979 0.211131L8.21761 8.07989L6.89076 1.38922C6.735 0.642357 6.12017 0.211131 5.43742 0.211131H0.0749505L0 0.546776C1.10083 0.773502 2.35157 1.13916 3.10927 1.53037C3.57303 1.76932 3.70536 1.97827 3.85761 2.5462L6.37079 11.772H9.7014L14.8074 0.211131H11.4979Z"
                    fill="url(#paint0_linear)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear"
                      x1="17.9299"
                      y1="12.1918"
                      x2="18.2583"
                      y2="-0.0841206"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#222357" />
                      <stop offset={1} stopColor="#254AA5" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <Input
                name="manager_attributes.card_number"
                type="text"
                placeholder="Digite"
                required
                defaultValue={company?.owner.phone_number}
              />
            </div>
            <Input
              name="expiration_month_year"
              label="Data de validade"
              type="text"
              placeholder="Digite"
              required
              mask="99/99"
              defaultValue={'expirationMonthYear'}
            />
            <Input
              name="manager_attributes.security_code"
              label="Código de segurança"
              type="number"
              placeholder="Digite"
              required
            />
          </div>
        </div>
        <div className="p-6">
          <button className="bg-primary focus:outline-none w-full text-black-100 font-bold rounded-full px-4 py-2">
            {isLoading ? <Loading /> : "Confirmar e Alterar"}
          </button>
        </div>
      </Form>
    </>
  );
};

export default SubscriptionForm;
